import {
  getSpecificPageVisibleItems,
  getStoreLogo,
  getAddressDetails,
  getActiveSocialMedia,
  getFooterRightReservedtext,
} from '../../utils/commonGetterFunction';
import { FooterDesktopContainer } from '../Footer1.styled';

const FooterDesktop = (props) => {
  const { footerState, store, propStyle, handleLogoClick } = props;
  return (
    <FooterDesktopContainer
      themeBackground={propStyle}
      className="footerDesktopContainer"
    >
      <div className="left-container">
        {getStoreLogo(footerState?.footerElements, handleLogoClick)}
      </div>
      <div className="middle-container">
        {getSpecificPageVisibleItems(footerState?.footerElements)}
        {getAddressDetails(footerState?.footerElements)}
      </div>
      <div className="right-container">
        {getActiveSocialMedia(store)}
        {getFooterRightReservedtext()}
      </div>
    </FooterDesktopContainer>
  );
};

export default FooterDesktop;

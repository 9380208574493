import styled from 'styled-components';
import { FOOTER_OPACITY } from '../../utils/common';

export const FooterContainer = styled.div`
  position: relative;
  width: 100%;
  &.bg_type_image {
    background: ${(props) =>
      props?.propsBackground?.content[0]?.url
        ? `url(${props?.propsBackground?.content[0]?.url})`
        : `none, ${props?.propsBackground?.default || props?.themeBackground}`};
  }
  &.bg_type_solid {
    background: ${(props) => props?.themeBackground?.backgroundColor};
  }
  /* &.bg_type_image::after {
    content: '';
    background: ${(props) => props?.themeBackground};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: ${FOOTER_OPACITY};
    z-index: 1;
  } */
  color: ${(props) => props?.themeBackground?.body?.color};
`;

export const FooterDesktopContainer = styled.div`
  width: 100%;
  padding: 2.5rem 4rem;
  display: flex;
  position: relative;
  z-index: 10;
  & .left-container {
    flex: 0.6;
    display: flex;
  }
  & .middle-container {
    flex: 1;
    align-items: center;
  }
  & .middle-container,
  .right-container {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
  }
  & .middle-container .privacy-terms-container {
    display: flex;
    gap: 1.5rem;
    font-size: 0.875rem;
    text-align: center;
    color: ${(props) => props?.themeBackground?.buttonSecondary?.color};
    font-family: ${(props) => props?.themeBackground?.buttonSecondary?.fontFamily};
    font-weight: ${(props) => props?.themeBackground?.buttonSecondary?.fontWeight};
  }
  & .middle-container .privacy-terms-container .privacy-policy,
  .middle-container .privacy-terms-container .terms-conditions {
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
  }
  & .middle-container .addressContainer {
    text-align: center;
    font-size: 0.813rem;
    font-weight: 500;
    line-height: 22px;
    color: ${(props) => props?.themeBackground?.body?.color};
    font-family: ${(props) => props?.themeBackground?.body?.fontFamily};
    font-weight: ${(props) => props?.themeBackground?.body?.fontWeight};
    display: flex;
    flex-wrap: wrap;
    overflow-wrap: break-word; /* Fallback for browsers that don't support 'anywhere' */
    overflow-wrap: anywhere; /* Use 'anywhere' for more flexible line breaking */
  }
  & .middle-container .addressContainer .address {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow-wrap: break-word; /* Fallback for browsers that don't support 'anywhere' */
    overflow-wrap: anywhere; /* Use 'anywhere' for more flexible line breaking */
  }
  & .middle-container .addressContainer .address .contact {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow-wrap: break-word; /* Fallback for browsers that don't support 'anywhere' */
    overflow-wrap: anywhere; /* Use 'anywhere' for more flexible line breaking */
  }
  & .right-container {
    align-items: flex-end;
    flex: 0.6;
  }

  & .right-container .footer-rights-container {
    text-align: right;
    display: flex;
    flex-direction: column;
    gap: 0.563rem;
    opacity: 0.6;
  }
  & .right-container .social-media-section {
    display: flex;
    justify-content: space-between;
  }
  & .right-container .footer-rights-container .copyright,
  & .right-container .footer-rights-container .copyright-sub-text {
    font-style: normal;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    color: ${(props) => props?.themeBackground?.body?.color};
    font-family: ${(props) => props?.themeBackground?.body?.fontFamily};
    font-weight: ${(props) => props?.themeBackground?.body?.fontWeight};
  }
  & .right-container .footer-rights-container .copyright {
    font-size: 0.75rem;
  }
  & .right-container .footer-rights-container .copyright-sub-text {
    font-size: 0.625rem;
  }
`;

export const FooterMobileContainer = styled.div`
  display: flex;
  padding: 2.5rem 1rem;
  flex-direction: column;
  gap: 2.5rem;
  position: relative;
  z-index: 10;
  & > .first-container {
    flex-direction: column;
    gap: 1.5rem;
  }
  & .first-container .privacy-terms-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    font-size: 0.875rem;
    text-align: center;
    color: ${(props) => props?.themeBackground?.buttonSecondary?.color};
    font-family: ${(props) => props?.themeBackground?.buttonSecondary?.fontFamily};
    font-weight: ${(props) => props?.themeBackground?.buttonSecondary?.fontWeight};
    overflow-wrap: break-word; /* Fallback for browsers that don't support 'anywhere' */
    overflow-wrap: anywhere; /* Use 'anywhere' for more flexible line breaking */
  }
  & > .second-container .social-media-section {
    display: flex;
    justify-content: center;
  }
  & .third-container .addressContainer {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    font-size: 0.813rem;
    line-height: 22px;
    color: ${(props) => props?.themeBackground?.body?.color};
    font-family: ${(props) => props?.themeBackground?.body?.fontFamily};
    font-weight: ${(props) => props?.themeBackground?.body?.fontWeight};
    overflow-wrap: break-word; /* Fallback for browsers that don't support 'anywhere' */
    overflow-wrap: anywhere; /* Use 'anywhere' for more flexible line breaking */
  }
  & .third-container .addressContainer .address {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow-wrap: break-word; /* Fallback for browsers that don't support 'anywhere' */
    overflow-wrap: anywhere; /* Use 'anywhere' for more flexible line breaking */
  }
  & .third-container .addressContainer .address .contact {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow-wrap: break-word; /* Fallback for browsers that don't support 'anywhere' */
    overflow-wrap: anywhere; /* Use 'anywhere' for more flexible line breaking */
  }
  & > .fourth-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & > .fifth-container .footer-rights-container .copyright,
  & > .fifth-container .footer-rights-container .copyright-sub-text {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-style: normal;
    opacity: 0.6;
    color: ${(props) => props?.themeBackground?.body?.color};
    font-family: ${(props) => props?.themeBackground?.body?.fontFamily};
    font-weight: ${(props) => props?.themeBackground?.body?.fontWeight};
    overflow-wrap: break-word; /* Fallback for browsers that don't support 'anywhere' */
    overflow-wrap: anywhere; /* Use 'anywhere' for more flexible line breaking */
  }
  & > .fifth-container .footer-rights-container .copyright {
    font-size: 0.75rem;
  }
  & > .fifth-container .footer-rights-container .copyright-sub-text {
    font-size: 0.625rem;
  }
`;
